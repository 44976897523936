$primary: #488dea;
$secondary: #7b828c;
$success: #1eb395;
$info: #2783b3;
$warning: #ffaa07;
$danger: #ff4655;
$light: #f3f5f7;
$dark: #1f2f3e;

$medium: #4a5461;
$silver: #a8b6c9;
$lightgrey: #dadcdf;
$lightergrey: #ecebef;
$purple: #7c71c3;

@import "./styles.scss";

.crisp-client { display: none !important; }

.auth-foreground {
	background: #1f2f3e url('/assets/img/auth/gradient-stripe-mep.svg');
	background-position: bottom 0 right -0.3125rem;
	background-size: 3.125rem auto;
	background-repeat: no-repeat;
}
