$theme-colors: (
	"medium": $medium,
	"silver": $silver,
	"lightgrey": $lightgrey,
	"lightergrey": $lightergrey,
	"purple": $purple
);

$text-muted: $secondary;

$body-color: $dark;

$box-shadow-sm: 0 .125rem .25rem rgba(#000, .15);
$box-shadow: 0 .5rem 1rem rgba(#000, .05);
$box-shadow-lg: 0 1rem 3rem rgba(#000, .05);

$font-family-sans-serif: 'Mulish', sans-serif;
$headings-font-family: 'Mulish', sans-serif;
$input-btn-font-family: 'Mulish', sans-serif;
$btn-font-family: 'Mulish', sans-serif;

$font-size-base: 0.8rem;
$small-font-size: 92%;
$line-height-lg: 1.4;
$line-height-sm: 1.4;
$line-height-base: 1.4;

$headings-font-weight: 700;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.35;
$h4-font-size: $font-size-base * 1.214;
$h5-font-size: $font-size-base * 1.071;
$h6-font-size: $font-size-base;

// $font-weight-lighter:         400;
// $font-weight-light:           400;
// $font-weight-normal:          700;
// $font-weight-bold:            900;
// $font-weight-bolder:          900;

$border-radius:               .25rem;
$border-radius-lg:            .25rem;
$border-radius-sm:            .25rem;

$card-border-width: 0;

$grid-gutter-width: 1.5rem;

$table-hover-bg: rgba($secondary, .1);
$table-border-color: rgba($secondary, .3);
$table-head-color: $secondary;
$table-bg-level: -11;

$pagination-color: $secondary;

$btn-padding-y: 0.532rem;
$btn-padding-x: 0.8rem;
$btn-font-size: 0.7rem;
$btn-font-size-sm: 0.7rem;
$btn-padding-y-lg: 0.8rem;
$btn-padding-x-lg: 1rem;
$btn-font-size-lg: 0.7rem;
$input-btn-line-height: 1.3;
$input-btn-line-height-sm: 1.3;

$dropdown-min-width: 100%;
$dropdown-font-size: 0.7rem;
$dropdown-padding-y: 0;
$dropdown-spacer: 0;
$dropdown-item-padding-x: 0.8rem;
$dropdown-item-padding-y: 0.594rem;
$dropdown-bg: $medium;
$dropdown-border-color: $medium;
$dropdown-link-color: #fff;
$dropdown-link-hover-color: #fff;
$dropdown-link-hover-bg: $primary;
$dropdown-link-disabled-color: $secondary;

$yiq-contrasted-threshold: 180; // Slight adjustment to colour contrast function to make sure all button texts are white.

$enable-caret: false;

$badge-padding-y: .3281rem;
$badge-padding-x: .3281rem;
$badge-font-size: 70%;

$sizes: (
	10: 10%,
	20: 20%,
	30: 30%,
	40: 40%,
	50: 50%,
	60: 60%,
	70: 70%,
	80: 80%,
	90: 90%,
	100: 100%
);

$custom-control-label-color: $secondary;
$custom-control-label-disabled-color: $silver;

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1600px
);

$modal-xxl: 1540px;
$modal-xl: 1140px;
$modal-lg: 900px;
$modal-md: 500px;
$modal-sm: 300px;

$input-placeholder-color: $secondary;

$custom-select-indicator-color: $secondary;
$custom-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='10'><path fill='#{$custom-select-indicator-color}' d='M4,8,8,2H0Z' /></svg>");
$custom-select-bg-size: 0.5rem 0.625rem;

@import "bootstrap/scss/bootstrap.scss";

html, body {
	height: 100%;
}

body {
	background: #edf0f3;
	overflow: hidden;
}

// Size helpers

.fill {
	width: 100%;
	height: 100%;
}

// Flex helpers

.flex-spaced { margin: 1.5rem; }
.flex-spaced .flex-row + .flex-row { margin-top: 1.5rem; }
.flex-spaced .flex-column + .flex-column { margin-left: 1.5rem; }

.flex-weight-0 { flex: initial !important; }
.flex-weight-1 { flex: 1; min-width: 0; min-height: 0; }
.flex-weight-2 { flex: 2; min-width: 0; min-height: 0; }
.flex-weight-3 { flex: 3; min-width: 0; min-height: 0; }
.flex-weight-4 { flex: 4; min-width: 0; min-height: 0; }
.flex-weight-5 { flex: 5; min-width: 0; min-height: 0; }
.flex-weight-6 { flex: 6; min-width: 0; min-height: 0; }
.flex-weight-7 { flex: 7; min-width: 0; min-height: 0; }
.flex-weight-8 { flex: 8; min-width: 0; min-height: 0; }
.flex-weight-9 { flex: 9; min-width: 0; min-height: 0; }
.flex-weight-10 { flex: 10; min-width: 0; min-height: 0; }
.flex-weight-11 { flex: 11; min-width: 0; min-height: 0; }
.flex-weight-12 { flex: 12; min-width: 0; min-height: 0; }

// Size helpers

.w-1r { width: 1rem !important; }
.w-2r { width: 2rem !important; }
.w-3r { width: 3rem !important; }
.w-4r { width: 4rem !important; }
.w-5r { width: 5rem !important; }
.w-6r { width: 6rem !important; }
.w-7r { width: 7rem !important; }
.w-8r { width: 8rem !important; }
.w-9r { width: 9rem !important; }
.w-10r { width: 10rem !important; }
.w-11r { width: 11rem !important; }
.w-12r { width: 12rem !important; }
.w-13r { width: 13rem !important; }
.w-14r { width: 14rem !important; }
.w-15r { width: 15rem !important; }
.w-16r { width: 16rem !important; }
.w-17r { width: 17rem !important; }
.w-18r { width: 18rem !important; }
.w-19r { width: 19rem !important; }
.w-20r { width: 20rem !important; }

// Text helpers

.text-tiny { font-size: 80%; }
.text-small { font-size: 92%; }
.text-large { font-size: 120%; }
.text-base { font-size: inherit; }
.text-bold { font-weight: $font-weight-bold; }
.text-italic { font-style: italic; }
.text-normal { font-weight: $font-weight-normal !important; }
.text-no-transform { text-transform: none !important; }
.text-pre { white-space: pre-line; }
.text-line-through { text-decoration: line-through; }

.text-lines {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;

	&.lines-1 { -webkit-line-clamp: 1; }
	&.lines-2 { -webkit-line-clamp: 2; }
	&.lines-3 { -webkit-line-clamp: 3; }
	&.lines-4 { -webkit-line-clamp: 4; }
	&.lines-5 { -webkit-line-clamp: 5; }
}

.nowrap { white-space: nowrap; }

// Table helpers

table.no-border, tr.no-border {
	td, th { border: none !important; }
}

table.shrink th, table.shrink td, th.shrink, td.shrink { width: 1%; }
table.vam th, table.vam td, tr.vam th, tr.vam td, th.vam, td.vam { vertical-align: middle !important; }
table.vat th, table.vat td, tr.vat th, tr.vat td, th.vat, td.vat { vertical-align: top !important; }

.table-md {
	th,
	td {
		padding: 0.5rem;
	}
}


// Border helpers

.border-1 { border-width: 1px !important; }
.border-2 { border-width: 2px !important; }
.border-3 { border-width: 3px !important; }
.border-4 { border-width: 4px !important; }
.border-5 { border-width: 5px !important; }
.border-6 { border-width: 6px !important; }
.border-7 { border-width: 7px !important; }
.border-8 { border-width: 8px !important; }
.border-9 { border-width: 9px !important; }
.border-10 { border-width: 10px !important; }

.border.border-dotted { border-style: dotted !important; }
.border-top.border-dotted { border-top-style: dotted !important; }
.border-bottom.border-dotted { border-bottom-style: dotted !important; }
.border-left.border-dotted { border-left-style: dotted !important; }
.border-right.border-dotted { border-right-style: dotted !important; }

// Other helpers

.selectable { cursor: pointer; }
.draggable { cursor: grab; }
.dimmed { opacity: 0.5; }

// PrimeNG styling

body .p-inputtext {
	@extend .form-control;

	border: $input-border-width solid $input-border-color !important;
}

.p-colorpicker .p-inputtext {
	width: 2rem !important;
}

body .p-inputtext:disabled {
	opacity: 1 !important;
	background: $input-disabled-bg !important;
}

body .p-inputtext:focus {
	color: $input-focus-color !important;
	border-color: $input-focus-border-color !important;
	box-shadow: $input-btn-focus-box-shadow !important;
}

body .input-group .input-group-prepend .p-inputtext {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.card-body tr .sticky {
	position: sticky;
	top: -$card-spacer-x * 1.05;
	z-index: 10;
	background-color: #fff;
}

tr .sticky {
	position: sticky;
	top: 0;
	z-index: 10;
	background-color: #fff;
}


.p-datepicker {
	padding: 0.5rem !important;
	min-width: 16rem !important;

	.p-datepicker-header {
		padding: 0 !important;
	}

	table {
		margin: 1rem 0 0 0 !important;
	}


	table tr th {
		text-align: center;
		padding: 0 0 1rem 0;
		font-size: 0.8rem;
	}

	table tr td {
		padding: 0;
		font-size: 0.8rem;
	}

	table tr td > span {
		width: 2rem;
		height: 2rem;

		&.p-highlight {
			background: $primary;
		}

		&:focus {
			border-color: $input-focus-border-color !important;
			box-shadow: $input-btn-focus-box-shadow !important;
		}
	}

	.p-datepicker-prev, .p-datepicker-next {
		&:hover {
			color: $primary !important;
		}

		&:focus {
			border-color: $input-focus-border-color !important;
			box-shadow: $input-btn-focus-box-shadow !important;
		}
	}
}

// Scrollbar

$scrollbar-color: lighten($secondary, 25%);

::-webkit-scrollbar-track {
	background-color: transparent;
	border-radius: 10px;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background-color: #f8f8f8;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: $scrollbar-color;
}

::-webkit-scrollbar-thumb:hover {
	background: darken($scrollbar-color, 7.5%);
}

// Custom styling

@each $color, $value in $theme-colors {
	.dropdown-item-#{$color}:focus, .dropdown-item-#{$color}:hover, .dropdown-item-#{$color}:active, .dropdown-item-#{$color}.active { background-color: $value; }
	.dropdown-item-#{$color}:focus { box-shadow: 0 0 0 0.2rem rgba($value, .5) !important; }
	.hoverable-#{$color}:hover { background: $value !important; }
	.bg-#{$color}-10 { background: rgba($value, .1); }
	.bg-#{$color}-25 { background: rgba($value, .25); }
	.bg-#{$color}-50 { background: rgba($value, .5); }
	.btn-style-invert { @include transition($btn-transition); }
	.btn-#{$color} .btn-style-invert { background: #fff; color: $value; }
	.btn-outline-#{$color} .btn-style-invert { background: $value; color: #fff; }
	.btn-outline-#{$color}:hover .btn-style-invert { background: #fff; color: $value; }

	.custom-control-#{$color} {
		.custom-control-input:focus~.custom-control-label::before {
			border-color: $value !important;
			box-shadow: 0 0 0 0.2rem rgba($value, 0.25) !important;
		}

		.custom-control-input:checked~.custom-control-label::before {
			border-color: $value !important;
			background-color: $value !important;
		}

		.custom-control-input:focus:not(:checked)~.custom-control-label::before {
			border-color: lighten($value, 25%) !important;
		}

		.custom-control-input:not(:disabled):active~.custom-control-label::before {
			background-color: lighten($value, 35%) !important;
			border-color: lighten($value, 35%) !important;
		}

		&.custom-checkbox {
			.custom-control-input:disabled {
				&:checked ~ .custom-control-label::before {
					background-color: rgba($value, 0.5) !important;
				}
				&:indeterminate ~ .custom-control-label::before {
					background-color: rgba($value, 0.5) !important;
				}
			}
		}
	}
}

.hoverable-table:hover { background: $table-hover-bg !important; }

th { color: $secondary; }

.table:not(.table-bordered) {
	th, td { border-top-style: dotted; }

	thead:first-child th { border-top: none; }
	thead th { border-bottom: none; }
	tr:first-child td { border-top: none; }
}

.table.table-bordered {
	th { border-bottom-width: 1px; }
}

.btn {
	text-transform: uppercase;
	font-weight: $font-weight-bold;

	i {
		font-size: 1.3em;
		vertical-align: middle;
		line-height: 0.769;
	}
}

.input-group .btn {
	padding-top: $input-btn-padding-y-sm;
	padding-bottom: $input-btn-padding-y-sm;
}

hr, .hr { border-top: 1px solid $lightgrey; }

.badge {
	line-height: 0.8;
	text-transform: uppercase;
	font-weight: $font-weight-bold;

	i { line-height: inherit; }
}

.dropdown.show button.dropdown-toggle {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

button.dropdown-toggle::after {
	content: '' !important;
}

.dropdown-menu {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.flat-left {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.flat-right {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.flat-top {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.flat-bottom {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.flat-top-left { border-top-left-radius: 0; }
.flat-top-right { border-top-right-radius: 0; }
.flat-bottom-left { border-bottom-left-radius: 0; }
.flat-bottom-right { border-bottom-right-radius: 0; }

.dropdown-item {
	font-weight: $font-weight-bold;
	text-transform: uppercase;

	i {
		font-size: 1.3em;
		vertical-align: middle;
		line-height: 0.769;
	}
}

.dropdown-item:focus {
	outline: 0;
	box-shadow: 0 0 0 $btn-focus-width rgba($primary, .5);
}

.dropdown-light {
	.dropdown-menu {
		@extend .shadow-sm;

		background: #fff;
		border-color: #fff;

		.dropdown-item {
			font-weight: normal !important;
			text-transform: none !important;
			color: $dark;
		}

		.dropdown-item:focus, .dropdown-item:hover, .dropdown-item:active, .dropdown-item.active { color: #fff; }
	}
}

.badge-group {
	display: inline-flex;
	vertical-align: middle;

	.badge {
		flex: 0;
		border: 1px solid $secondary;
	}

	.badge-left {
		margin-right: 0;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right: none;
	}

	.badge-right {
		margin-left: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: none;
	}

	.badge-middle {
		margin-right: 0;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right: none;

		margin-left: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: none;
	}
}

.col-form-label {
	color: $dark;
	font-weight: $font-weight-bold;
}

.form-inline ui-form-control:not(:last-child) { margin-right: 1.5rem; }
ui-form:last-child > div { margin-bottom: -1rem !important; }

.custom-control-label {
	font-size: $btn-font-size;
	text-transform: uppercase;
	font-weight: $font-weight-bold;
	display: inline-block;

	&::before { top: 0; }
	&::after { top: 0; }
}

.product-image {
	position: relative;
	width: 3rem;
	height: 3rem;
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: middle;
}

.img-background {
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
}

// Navigation

.navigation {
	td.navigation-header {
		padding: 1.5rem 1rem 0.6rem 1rem;
		background: #fff !important;
		text-transform: uppercase;
		font-size: $small-font-size;
		font-weight: $font-weight-bold;
	}

	td.navigation-link {
		padding: 0;

		a {
			display: block;
			padding: 0.8rem 1rem;
			color: $dark;

			i {
				color: $secondary;
				vertical-align: middle;
				font-size: 120%;
			}
		}

		&.active {
			a {
				background: $medium;
				color: #fff;

				i { color: #fff; }
			}
		}
	}

	td.navigation-subitem {
		padding: 0;

		a {
			font-weight: $font-weight-bold;
			display: block;
			padding: 0.2rem 0.25rem;
			color: $dark;

			span {
				padding: 0.3rem 0.75rem;
				display: block;
				border-radius: 1.25rem;
			}
		}

		&.first a { padding-top: 0.6rem; }
		&.last a { padding-bottom: 0.6rem; }

		a:hover span {
			background: $table-hover-bg;
		}

		&.active {
			a {
				span {
					background: $secondary;
					color: $light;
				}
			}
		}
	}

}

.subtitle {
	font-size: 92%;
	font-weight: bold;
	text-transform: uppercase;
	color: $secondary;
}

.scrollable { overflow: auto; }
.scrollable-x { overflow: auto hidden; }
.scrollable-y { overflow: hidden auto; }

.hoverable:hover { background: $lightergrey !important; }

.hover-child { visibility: hidden; }
.hover-parent:hover .hover-child { visibility: visible; }
.hover-parent:not(:hover) .hover-show { display: none; }
.hover-parent:hover .hover-hide { display: none; }

// Card padding classes

.m-nc { margin: -$card-spacer-x; }
.mt-nc { margin-top: -$card-spacer-x; }
.mb-nc { margin-bottom: -$card-spacer-x; }
.ml-nc { margin-left: -$card-spacer-x; }
.mr-nc { margin-right: -$card-spacer-x; }
.mx-nc { margin-left: -$card-spacer-x; margin-right: -$card-spacer-x; }
.my-nc { margin-top: -$card-spacer-x; margin-bottom: -$card-spacer-x; }

.m-c { margin: $card-spacer-x; }
.mt-c { margin-top: $card-spacer-x; }
.mb-c { margin-bottom: $card-spacer-x; }
.ml-c { margin-left: $card-spacer-x; }
.mr-c { margin-right: $card-spacer-x; }
.mx-c { margin-left: $card-spacer-x; margin-right: $card-spacer-x; }
.my-c { margin-top: $card-spacer-x; margin-bottom: $card-spacer-x; }

.p-c { padding: $card-spacer-x; }
.pt-c { padding-top: $card-spacer-x; }
.pb-c { padding-bottom: $card-spacer-x; }
.pl-c { padding-left: $card-spacer-x; }
.pr-c { padding-right: $card-spacer-x; }
.px-c { padding-left: $card-spacer-x; padding-right: $card-spacer-x; }
.py-c { padding-top: $card-spacer-x; padding-bottom: $card-spacer-x; }

// Print

@media print {
	.no-print { display: none !important; }
	.shadow { box-shadow: none !important; }
	.flex-spaced { margin: 0 !important; }
	.flex-spaced .flex-row + .flex-row { margin: 0 !important; }
	.flex-spaced .flex-column + .flex-column { margin: 0 !important; }

	ui-grid-container.h-100 { height: auto !important; }

	hr {
		border-color: #000;
		background-color: #000;
		color: #000;
	}

	html {
		font-size: 16px;
	}

	body {
		font-size: 16px;
		background: #fff;
	}

	.badge { border: none !important; }

	.print-footer {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
	}

	ui-card .card-body:first-child hr { border-width: 3px; }

}

.print-group {
	page-break-before: avoid;
	page-break-inside: avoid;
	page-break-after: avoid;
}

.print-break-before { page-break-before: always; }
.print-break-after { page-break-after: always; }

// Mobile and Tablets

.show-mobile { display: none; }
.show-tablet { display: none; }

@media (max-width: 767.98px) {
	.hide-mobile { display: none !important; }
	.show-mobile { display: block !important; }
}

@media (max-width: 991.98px) {
	.hide-tablet { display: none !important; }
	.show-tablet { display: block !important; }
}

// Search

input::-webkit-search-cancel-button{
	margin-right: -5px;
}

// Fix Bootstrap multiple modals

.modal {
	background: rgba(0,0,0,0.41);
}

.modal-backdrop.show {
	opacity: 0;
}

// Fix Bootstrap nested contextual table backgrounds
@each $color, $value in $theme-colors {
	$temp_border: theme-color-level($color, $table-border-level);
	$temp_background: theme-color-level($color, $table-bg-level);

	td.table-#{$color}, th.table-#{$color} {
		background-color: $temp_background;

		@if $temp_border != null {
			border-color: $temp_border;
		}
	}

	.table-hover {
		$hover-background: darken($temp_background, 5%);

		tr:hover {
			td.table-#{$color}, th.table-#{$color} {
				background-color: $hover-background !important;
			}
		}
	}
}

// Add XXL modal size

@include media-breakpoint-up(lg) {
	.modal-xxl { max-width: $modal-lg; }
}

@include media-breakpoint-up(xl) {
	.modal-xxl { max-width: $modal-xl; }
}

@include media-breakpoint-up(xxl) {
	.modal-xxl { max-width: $modal-xxl; }
}

// Tutorial

.tutorial-highlight {
	z-index: 100;
	position: relative;
	animation: tutorial-pulse 1s infinite;
}

@keyframes tutorial-pulse {
	0% {
		box-shadow: 0 0 0 0 rgba($primary, 0.9), 0 0 0.5rem 0.5rem rgba($primary, 0.4);
	}
	100% {
		box-shadow: 0 0 0rem 1.5rem rgba($primary, 0), 0 0 0.5rem 0.5rem rgba($primary, 0.4);
	}
}

// TinyMCE

.tox .tox-dialog-wrap__backdrop { background-color: rgba(0, 0, 0, 0.5) !important; }

// Editable content

.edit-content-button {
	position: absolute;
	top: 0;
	right: 0;
}

.edit-content-container {
	min-height: 50px;
	max-height: 300px;
	overflow: auto;
	position: relative;

	img { max-width: 100%; }
}

.user-content {
	img { max-width: 100%; }
}

// Extra icon helpers

i.wq.xl { font-size: 1.2rem !important; }

// TODO: Remove this after upgrading to Bootstrap v5
// Handle ng-bootstrap differences between Bootstrap v4 and v5

.popover-arrow {
	@extend .arrow;
}

.tooltip-arrow {
	@extend .arrow;
}

// Dynamic base font size based on screen size

html { font-size: 12px; }

@include media-breakpoint-up(sm) {
	html { font-size: 12px; }
}

@include media-breakpoint-up(md) {
	html { font-size: 12px; }
}

@include media-breakpoint-up(lg) {
	html { font-size: 12px; }
}

@include media-breakpoint-up(xl) {
	html { font-size: 13px; }
}

@include media-breakpoint-up(xxl) {
	html { font-size: 16px; }
}

@media (max-width: 300px) { .hide-below-300 { display: none; } }
@media (max-width: 400px) { .hide-below-400 { display: none; } }
@media (max-width: 500px) { .hide-below-500 { display: none; } }
@media (max-width: 600px) { .hide-below-600 { display: none; } }
@media (max-width: 700px) { .hide-below-700 { display: none; } }
@media (max-width: 800px) { .hide-below-800 { display: none; } }
@media (max-width: 900px) { .hide-below-900 { display: none; } }
@media (max-width: 1000px) { .hide-below-1000 { display: none; } }
@media (max-width: 1100px) { .hide-below-1100 { display: none; } }
@media (max-width: 1200px) { .hide-below-1200 { display: none; } }
@media (max-width: 1300px) { .hide-below-1300 { display: none; } }
@media (max-width: 1400px) { .hide-below-1400 { display: none; } }
@media (max-width: 1500px) { .hide-below-1500 { display: none; } }
@media (max-width: 1600px) { .hide-below-1600 { display: none; } }
@media (max-width: 1700px) { .hide-below-1700 { display: none; } }
@media (max-width: 1800px) { .hide-below-1800 { display: none; } }
@media (max-width: 1900px) { .hide-below-1900 { display: none; } }
@media (max-width: 2000px) { .hide-below-2000 { display: none; } }
